import ScrollToTop from 'components/candidat/common/ScrollToTop';
import CandidatDetails from 'components/Dashboard/Candidat/CandidatDetails';
import CalculContigence from 'components/Dashboard/Note/CalculContigence';
import Contigence from 'components/Dashboard/Note/Contigence/Contigence';
import GestionNote from 'components/Dashboard/Note/GestionNote/GestionNote';
import ResultatImportExcel from 'components/Dashboard/Note/Resultat/ResultatImportExcel';
import NoteLayout from 'layouts/NoteLayout';
import Candidat from 'pages/admin/Candidat';
import CentreBacc from 'pages/admin/CentreBacc';
import CentreConcours from 'pages/admin/CentreConcours';
import Choix from 'pages/admin/Choix';
import Droit from 'pages/admin/Droit';
import Epreuve from 'pages/admin/Epreuve';
import Faritany from 'pages/admin/Faritany';
import Login from 'pages/admin/Login';
import Note from 'pages/admin/Note';
import Parametre from 'pages/admin/Parametre';
import Resultat from 'pages/admin/Resultat';
import Serie from 'pages/admin/Serie';
import TypeCandidat from 'pages/admin/TypeCandidat';
import Rentree from 'pages/candidat/Rentree';
import { useEffect } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import AdminLayout from './layouts/AdminLayout';
import CandidatLayout from './layouts/CandidatLayout';
import AdminHome from './pages/admin/Home';
import Test from './pages/admin/Test';
import CandidatHome from './pages/candidat/Home';
import CandidatInscription from './pages/candidat/Inscription';
import CandidatResultats from './pages/candidat/Resultats';
import NotFound from './pages/NotFound';
import GlobalStyle from './styles/GlobalStyle';
import Statistic from 'pages/admin/Statistic';

function App() {
  useEffect(() => {
    //Desactiver click droit
    // document.onselectstart = new Function("return false");
    // document.oncontextmenu = new Function("return false");
  }, []);
  return (
    <>
      <GlobalStyle />
      <BrowserRouter>
        <ScrollToTop>
          <Routes>
            {/* routes for candidates  */}
            <Route exact path="/" element={<CandidatLayout />}>
              <Route index path="rentree" element={<Rentree />} />
              <Route index element={<CandidatHome />} />
              <Route path="concours" element={<CandidatHome />} />
              <Route exact path="/inscription" element={<CandidatInscription />} />
              <Route path="resultats" element={<CandidatResultats />} />
            </Route>

            {/* routes for admin  */}
            <Route path="/toor/login" element={<Login />} />
            <Route path="/toor" element={<AdminLayout />}>
              <Route index element={<AdminHome />} />
              <Route path="test" element={<Test />} />
              <Route path="centreConcours" element={<CentreConcours />} />
              <Route path="centreBacc" element={<CentreBacc />} />
              <Route path="faritany" element={<Faritany />} />
              <Route path="choix" element={<Choix />} />
              <Route path="parametre" element={<Parametre />} />
              <Route path="droit" element={<Droit />} />
              <Route path="typeCandidat" element={<TypeCandidat />} />
              <Route path="epreuve" element={<Epreuve />} />
              <Route path="resultat" element={<Resultat />} />
              <Route path="candidat" element={<Candidat />} />
              <Route path="statistic" element={<Statistic />} />
              <Route path="candidat/:numInscription" element={<CandidatDetails />} />
              <Route path="serie" element={<Serie />} />
              <Route exact path="inscription" element={<CandidatInscription />} />
            </Route>

            <Route path="/note" element={<NoteLayout />}>
              <Route path="ajoutNote" element={<Note />} />
              <Route path="gestionNote" element={<GestionNote />} />
              <Route path="contigence" element={<Contigence />} />
              <Route path="resultat" element={<ResultatImportExcel />} />
              <Route path="calcContigence" element={<CalculContigence />} />
            </Route>

            <Route path="*" element={<NotFound />} />
          </Routes>
        </ScrollToTop>
      </BrowserRouter>
    </>
  );
}

export default App;
