import { Link } from "react-router-dom";
import styled from "styled-components";

import Logo from "../../../assets/img/eni-logo.png";

import * as styles from "../../../styles/variables";

export default function Nav() {
  return (
    <NavWrapper>
      <div className="nav">
        <Link to="/">
          <img src={Logo} alt="logo" className="logo" />
        </Link>
        <div>
          <ul>
            {/* <Link
              to="/resultats"
              className="link"
              style={{ textDecoration: "none" }}
            >
              Résultats 2023
            </Link>
            <Link
              to="/rentree"
              className="link"
              style={{ textDecoration: "none" }}
            >
              Rentrée 2023
            </Link> */}
            <Link
              to="inscription"
              className="link"
              style={{ textDecoration: "none" }}
            >
              S'inscrire au concours
            </Link>
          </ul>
        </div>
      </div>
    </NavWrapper>
  );
}

const NavWrapper = styled.div`
  height: ${styles.size.navHeight};
  background-color: #fff;
  position: fixed;
  width: 100%;
  top: 0;
  padding: 0 5%;

  display: flex;
  justify-content: center;

  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px;

  z-index: 999;

  .nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    width: 100%;

    max-width: 1280px;
  }

  .logo {
    height: 60px;
    width: 60px;
  }

  ul {
    display: flex;
    align-items: center;
    list-style: none;
    li,
    .link {
      margin: 0 30px;
      text-transform: uppercase;
      font-size: 14px;
      font-weight: bold;
      color: ${styles.colors.darkBlue};
      cursor: pointer;

      @media (max-width: ${styles.breakpoints.sm}) {
        font-size: 12px;
      }
    }
  }
`;
