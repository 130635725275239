export const onlineDocuments = [
  {
    id: 1,
    title: "Bordereau de versement",
    text: "Un bordereau de versement du frais de scolarité de 472.500 Ar pour les candidats nationaux au compte  N° 21 000 135 638 01, Société Générale Madagasikara (ex BFV-SG) Agence de Fianarantsoa, code 00340",
  },
  {
    id: 2,
    title: "Fiche d'inscription",
    text: "Une fiche d’inscription à remplir; récupérable à l’ENI ou téléchargeable ici",
    button: true,
  },
];

export const physicalDocuments = [
  {
    id: 3,
    title: "Diplôme du Baccalauréat ou relevé des notes",
    text: "Une (01) copie certifiée conforme du Diplôme ou du relevé des notes Baccalauréat. ",
  },
  //   {
  //     id: 2,
  //     title: "Copie dActe d'Etat civil",
  //     text: "Une (01) copied’Acte d'Etat civil datant de moins d'un an, ou le cas échéant (sous réserve), un bulletin de naissance datant de moins d’un an ou d’une photocopie certifiée conforme de la CIN ou d’un passeport en cours de validité,",
  //   },
  {
    id: 4,
    title: "Une photo d'identité",
    text: "Une photo d’identité récente et identique en couleur.",
  },
  {
    id: 5,
    title: "Une enveloppe A4",
    text: "Une enveloppes A4, timbrées à 1 000Ar portant l’adresse précise du (de la) candidat(e).",
  },
];
