import {
  Backdrop,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Fade,
  FormControlLabel,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import axios from "axios";
import React, { useState } from "react";
import { useEffect } from "react";
import WarningSnackBar from "../Snackbar/WarningSnackBar";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const EditNote = (props) => {
  const [formData, setFormData] = useState(0);
  const [openUndefinedSnack, setOpenUndefinedSnack] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [presence, setPresence] = useState(false);

  //Quand on change la valeur dans l'input
  const handleChangeFormData = (event) => {
    event.preventDefault();
    const { value, name } = event.target;
    const noteUpdated = value.slice(0, 5);
    if (presence) {
      if (+noteUpdated <= 20 && +noteUpdated >= 0) {
        setFormData(noteUpdated);
      } else {
        setErrorMessage("Invalid note (Le note doit comprise entre 0 à 20) ");
        setOpenUndefinedSnack(true);
      }
    } else {
      setFormData(0);
      setErrorMessage("Ce candidat est absent, alors sa note est forcement 0");
      setOpenUndefinedSnack(true);
    }
  };

  //Quand on change onservation
  const handleChange = (event) => {
    setPresence(event.target.checked);
    if (event.target.checked === false) {
      setFormData(0);
    }
  };

  //Bloquer e
  const blockInvalidChar = (e) => {
    if (e.key === "Enter") {
      editNote();
    }
    ["e", "E", "+", "-"].includes(e.key) && e.preventDefault();
  };

  const editNote = () => {
    let idComposerEdit = props.candidatToEdit.composer.filter((d) => {
      return d.codeEpreuve.codeEpreuve === props.candidatToEdit.idNote;
    })[0].id;
    axios
      .put(`/composer/${idComposerEdit}`, {
        ...props.candidatToEdit,
        Note: formData,
        codeEpreuve: props.candidatToEdit.codeEpreuve.codeEpreuve,
        Presence: presence,
        composer: null,
      })
      .then((res) => {
        if (res.data.error == false) {
          props.handleUpdateNote({
            ...props.candidatToEdit,
            Note: formData,
            codeEpreuve: props.candidatToEdit.codeEpreuve.codeEpreuve,
            Presence: presence,
          });
        } else {
          console.error("error");
        }
      });
  };
  useEffect(() => {
    setFormData(props.candidatToEdit.Note);
    setPresence(props.candidatToEdit.Presence);
  }, [props.candidatToEdit]);

  if (
    props.candidatToEdit !== undefined &&
    props.candidatToEdit.codeEpreuve !== undefined
  ) {
    return (
      <div>
        <Dialog
          open={props.open}
          onClose={props.handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {`Edition note ${
              props.candidatToEdit?.codeEpreuve?.libelleEpreuve
            } de N°Inscription ${props.candidatToEdit?.numInscription?.slice(
              0,
              2
            )} ${props.candidatToEdit?.numInscription?.slice(2)}`}
          </DialogTitle>
          <DialogContent>
            <TextField
              sx={{ width: "100%" }}
              margin="dense"
              type="number"
              name="note"
              value={formData}
              onChange={handleChangeFormData}
              required
              placeholder={`Saissisez ici la note de ${props.candidatToEdit.numInscription.slice(
                0,
                2
              )} ${props.candidatToEdit.numInscription.slice(2)} (Note /20)`}
              onKeyDown={blockInvalidChar}
            />

            <FormControlLabel
              value="end"
              control={<Checkbox checked={presence} onChange={handleChange} />}
              label="Absence"
              labelPlacement="end"
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={props.handleClose}>Annuler</Button>
            <Button onClick={editNote} autoFocus>
              Valider
            </Button>
          </DialogActions>
        </Dialog>
        <WarningSnackBar
          open={openUndefinedSnack}
          message={errorMessage}
          close={() => setOpenUndefinedSnack(false)}
        />
      </div>
    );
  } else {
    return <></>;
  }
};

export default EditNote;
