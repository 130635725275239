import NoteTable from "components/Dashboard/Note/NoteTable";
import React from "react";

const Note = (props) => {
    return (
      <>
        <NoteTable />
      </>
    );
};

export default Note;
