import { Button } from '@mui/material';
import axios from 'axios';
import React, { useRef } from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { useDownloadExcel } from 'react-export-table-to-excel';
import styled from 'styled-components';
import FileDownloadIcon from '@mui/icons-material/FileDownload';

const CalculContigence = (props) => {
  const [contigence, setContigence] = useState();
  const [exportExcel, setExportExcel] = useState(false);

  const tableRef = useRef(null);

  useEffect(() => {
    fetchContigence();
  }, []);

  const fetchContigence = () => {
    axios.get(`/candidat/contigence/province`).then((res) => {
      setContigence(res.data);
    });
  };

  const fixedValue = (value, numberDigit = 2) => {
    return value.toFixed(numberDigit);
  };

  /**
   * Download table contigente
   */
  const { onDownload } = useDownloadExcel({
    currentTableRef: tableRef.current,
    filename: 'Contigence_finale',
    sheet: 'Contigence',
  });

  return (
    <Wrapper>
      <Button
        variant="contained"
        onClick={(e) => {
          e.preventDefault();
          setExportExcel(true);
          setTimeout(() => {
            onDownload();
            setExportExcel(false);
          }, 4000);
        }}
        color="success"
        style={{ margin: '10px' }}
        endIcon={<FileDownloadIcon />}
      >
        Export excel
      </Button>
      <table ref={tableRef}>
        <thead>
          <tr>
            <th colSpan="4"></th>
            {contigence && contigence.choix.map((c) => <th>{c.nbPlaces}</th>)}
          </tr>

          <tr>
            <th></th>
            <th colSpan="3" style={{ textAlign: 'center' }}>
              INSCRIPTION
            </th>
            <th colSpan="3" style={{ textAlign: 'center' }}>
              CONTINGENT THEORIQUE
            </th>
            <th colSpan="3" style={{ textAlign: 'center' }}>
              CONTINGENT AJUSTE
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td></td>
            {[1, 2, 3].map((b) => contigence && contigence.choix.map((c) => <th>{c.libelleChoix}</th>))}
          </tr>
          {contigence &&
            Object.entries(contigence?.inscription).map(([property, value]) => (
              <>
                <tr>
                  <td>{property}</td>
                  {/* total parcours par province */}

                  {Object.entries(value?.parcours).map(([property_p, value_p]) => (
                    <th>{value_p.total}</th>
                  ))}
                  {/* total prendre par parcours par province */}
                  {Object.entries(value?.parcours).map(([property_p, value_p]) => (
                    <th>{fixedValue(value_p.prendre)} </th>
                  ))}
                </tr>
              </>
            ))}
          {contigence && (
            <tr>
              <td></td>
              {/* total parcours par province */}
              {Object.entries(contigence?.nombreTotalChoix).map(([property, value]) => (
                <td>{value}</td>
              ))}
              {/* total prendre par parcours par province */}

              {Object.entries(contigence?.nombreTotalPrendreChoix).map(([property, value]) => (
                <td>{fixedValue(value)}</td>
              ))}
            </tr>
          )}
        </tbody>
      </table>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  table {
    font-family: arial, sans-serif;
    border-collapse: collapse;
    width: 100%;
  }

  td,
  th {
    border: 1px solid #dddddd;
    text-align: left;
    padding: 8px;
  }

  tr:nth-child(even) {
    // background-color: #dddddd;
  }
`;

export default CalculContigence;
