import React from "react";
import styled from "styled-components";
import CircleIcon from "@mui/icons-material/Circle";
import * as styles from "../../styles/variables";
import { onlineDocuments, physicalDocuments } from "constants/rentreeData";
import { OutlineButton } from "styles/candidat/Button.styled";
import DownloadIcon from "@mui/icons-material/Download";
import { Link } from "react-router-dom";

const Rentree = () => {
  return (
    <RentreeWrapper>
      <div className="container">
        <div className="header">
          <div className="bg-blue">
            <h1>Admis au concours?</h1>
          </div>
          <div className="details">
            <p>
              <strong>Félicitations !</strong>
            </p>
            <p>Entamez dès maintenant votre inscription en première année</p>
          </div>
          <div className="buttons">
            <Link to="/resultats" className="btn-outline">
              Suis-je admis?
            </Link>
            <a href="#documents" className="btn-filled">
              Liste des dossiers à fournir
            </a>
          </div>
        </div>
        <div className="dates">
          <div className="card">
            <div className="circleIcons">
              <CircleIcon
                sx={{
                  width: 16,
                  height: 16,
                  marginRight: "6px",
                  color: "#2BFF4D",
                }}
              />
              <CircleIcon
                sx={{
                  width: 16,
                  height: 16,
                  marginRight: "6px",
                  color: "#D9D9D9",
                }}
              />
            </div>
            <p>Début des inscriptions</p>
            <h2>Lundi 2 Octobre 2023</h2>
          </div>
          <div className="card">
            <div className="circleIcons">
              <CircleIcon
                sx={{
                  width: 16,
                  height: 16,
                  marginRight: "6px",
                  color: "#D9D9D9",
                }}
              />
              <CircleIcon
                sx={{
                  width: 16,
                  height: 16,
                  marginRight: "6px",
                  color: "#FF4F4F",
                }}
              />
            </div>
            <p>Fin des inscriptions</p>
            <h2>Mardi 31 Octobre 2023</h2>
          </div>
        </div>
        <div className="documents">
          <div className="title">
            <h1>
              Liste des dossiers <br /> à fournir
            </h1>
          </div>
          <div className="documents-container">
            <h2>
              Dossiers à délivrer par poste ou à déposer directement à l'ENI
            </h2>
            <p>Les dossiers ci-dessous sont à envoyer par:</p>
            <ul>
              {/* <li>
                e-mail à l'adresse <strong>scolarite@eni.mg</strong>
              </li> */}
              <li>
                Poste à l’ENI BP 1487 Fianarantsoa (301) ou à déposer
                directement à l’ENI
              </li>
            </ul>
            <div className="documents" id="documents">
              {onlineDocuments.map((document) => (
                <div className="item" key={document.id}>
                  <h1>0{document.id}.</h1>
                  <div className="content">
                    <p className="doc-title">{document.title}</p>
                    <p className="text">{document.text}</p>
                    {document.button && (
                      <a
                        href="/documents/Fiche_inscription.pdf"
                        download
                        style={{
                          textDecoration: "none",
                          display: "inline-block",
                        }}
                      >
                        <OutlineButton
                          borderColor={styles.colors.lightBlue}
                          rounded
                          color={styles.colors.lightBlue}
                        >
                          Télécharger la fiche
                          <DownloadIcon color={styles.colors.lightBlue} />
                        </OutlineButton>
                      </a>
                    )}
                  </div>
                </div>
              ))}
            </div>
          </div>
          {/* <br />
          <br />
          <br />
          <br />
          <br /> */}
          {/* <div
            style={{
              width: "160px",
              height: "10px",
              backgroundColor: "#2F80ED",
            }}
          ></div> */}
          {/* <br />
          <br /> */}
          <div className="documents-container">
            {/* <h2>Dossiers à déposer directement à l'ENI</h2> */}
            <div className="documents">
              {physicalDocuments.map((document) => (
                <div className="item" key={document.id}>
                  <h1>0{document.id}.</h1>
                  <div className="content">
                    <p className="doc-title">{document.title}</p>
                    <p className="text">{document.text}</p>
                    {document.button && (
                      <a
                        href="/documents/Fiche_inscription.pdf"
                        download
                        style={{
                          textDecoration: "none",
                          display: "inline-block",
                        }}
                      >
                        <OutlineButton
                          borderColor={styles.colors.lightBlue}
                          rounded
                          color={styles.colors.lightBlue}
                        >
                          Télécharger la fiche
                          <DownloadIcon color={styles.colors.lightBlue} />
                        </OutlineButton>
                      </a>
                    )}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </RentreeWrapper>
  );
};

const RentreeWrapper = styled.div`
  margin-top: ${styles.size.navHeight};
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 80px 5%;

  .container {
    max-width: 1280px;
    width: 100%;

    .header {
      .bg-blue {
        background-color: ${styles.colors.darkBlue};
        display: inline-block;
        padding: 10px 30px;
        h1 {
          margin: 0;
          font-size: 48px;
          color: #fff;
        }
      }
      .details {
        margin-top: 40px;
        p {
          max-width: 400px;
        }
      }
      .buttons {
        display: flex;
        .btn-outline {
          background-color: none;
          height: 44px;
          display: flex;
          justify-content: center;
          align-items: center;
          border: 1px solid ${styles.colors.darkBlue};
          border-radius: 12px;
          text-decoration: none;
          color: ${styles.colors.darkBlue};
          font-weight: 700;
          padding: 0 20px;
          text-align: center;
          margin-right: 20px;
          font-size: 12px;
          cursor: pointer;
        }

        .btn-filled {
          background-color: ${styles.colors.lightBlue};
          height: 44px;
          display: flex;
          justify-content: center;
          align-items: center;
          text-align: center;
          border-radius: 12px;
          text-decoration: none;
          color: #fff;
          font-size: 12px;
          padding: 0 20px;
          font-weight: 600;
          cursor: pointer;
        }
      }
    }

    .dates {
      margin-top: 80px;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;

      .card {
        flex-basis: 48%;
        padding: 40px 20px;
        border: 1px solid ${styles.colors.lightBlue};
        border-radius: 20px;
        p {
          font-size: 14px;
          font-weight: 700;
        }

        h2 {
          font-size: 34px;
          margin: 0;
          color: ${styles.colors.lightBlue};
        }
      }
    }

    .documents {
      margin-top: 120px;
      .title {
        display: flex;
        justify-content: center;
        color: ${styles.colors.lightBlue};
        text-align: center;
      }

      .documents-container {
        margin-top: 60px;
        .documents {
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;
          margin-top: 40px;
          .item {
            flex-basis: 45%;
            display: flex;
            h1 {
              color: ${styles.colors.lightBlue};
              margin: 0;
            }
            .content {
              display: flex;
              flex-direction: column;
              justify-content: flex-start;
              margin-left: 10px;
              .doc-title {
                color: #000;
                font-size: 16px;
                font-weight: bold;
              }
              .text {
                font-size: 14px;
              }
            }
          }
        }
      }
    }
  }
  @media (max-width: ${styles.breakpoints.md}) {
    .container {
      .documents {
        .documents-container {
          .documents {
            flex-direction: column;
          }
        }
      }
      .dates {
        flex-direction: column;
        .card {
          margin-bottom: 40px;
          h2 {
            font-size: 24px;
          }
        }
      }
    }
  }
  @media (max-width: ${styles.breakpoints.sm}) {
    .container {
      .header {
        .bg-blue {
          h1 {
            font-size: 24px;
          }
        }
        .buttons {
          flex-direction: column;
          .btn-outline {
            margin-right: 0px !important;
            margin-bottom: 10px;
          }
        }
      }
    }
  }
`;

export default Rentree;
