import { Backdrop, Button, CircularProgress, IconButton } from "@mui/material";
import axios from "axios";
import React, { Component, useRef, useState } from "react";
import { useEffect } from "react";
import { useDownloadExcel } from "react-export-table-to-excel";
import styled from "styled-components";
import FileDownloadIcon from "@mui/icons-material/FileDownload";

const Contigence = (props) => {
  const tableRef = useRef(null);
  const [contigence, setContigence] = useState([]);
  const [series, setSeries] = useState([]);
  const [dataChoix, setDataChoix] = useState([]);
  const [open, setOpen] = React.useState(false);

  const { onDownload } = useDownloadExcel({
    currentTableRef: tableRef.current,
    filename: "Contigence",
    sheet: "Contigence",
  });

  const getAllCandidat = () => {
    setOpen(true);
    try {
      axios
        .get("candidat/contigences/excel")
        .then((response) => {
          setContigence(response.data);
          setOpen(false);
        })
        .catch((error) => {});
    } catch (error) {
      console.error(error);
    }
  };

  const fetchDataSerie = () => {
    axios.get(`/series`).then((res) => {
      setSeries(res.data);
    });
  };

  const fetchDataChoix = () => {
    axios.get(`/choix`).then((res) => {
      setDataChoix(res.data);
    });
  };

  useEffect(() => {
    fetchDataChoix();
    fetchDataSerie();
    getAllCandidat();
  }, []);

  const getChoix = (candidat, idChoix) => {
    if (candidat.premierChoix.codeFormation === idChoix) return 1;
    if (candidat.deuxiemeChoix.codeFormation === idChoix) return 2;
  };

  return (
    <Wrapper>
      <div className="container">
        <Button
          variant="contained"
          onClick={onDownload}
          color="success"
          className="exportexcel"
          endIcon={<FileDownloadIcon />}
        >
          Export excel
        </Button>
        <span>
          <b> {contigence.length} </b>candidats
        </span>
        <table ref={tableRef}>
          <thead>
            <tr>
              <td>Numéro</td>
              <td>Nom et prénom</td>
              <td>Téléphone</td>
              {dataChoix.map((c, i) => (
                <th key={c.libelleChoix}> {c.libelleChoix}</th>
              ))}
              <td>Serie</td>
              <td>Mention</td>
              <td>Année</td>
              <td>Contigence</td>
            </tr>
          </thead>
          <tbody>
            {contigence
              .sort(function (a, b) {
                if (a.convocation.numero < b.convocation.numero) {
                  return -1;
                } else {
                  return 1;
                }
              })
              .map((c, i) => (
                <tr key={`${c.convocation.numero}_${i}`}>
                  <td>
                    <b>{c.convocation.numero}</b>
                  </td>
                  <td>
                    <b>
                      {c.nom} {c.prenoms}
                    </b>
                  </td>
                  <td>
                      {c.telephone}
                  </td>
                  {dataChoix.map((ch, i) => (
                    <td key={ch.libelleChoix + "_" + i}>
                      {getChoix(c, ch.codeChoix)}
                    </td>
                  ))}
                  <td>{c.codeSerie.libelleSerie}</td>
                  <td>{c.mention}</td>
                  <td>{c.anneeBacc}</td>
                  <td>{c.codeCentreBacc.codeFaritany.toString().charAt(0)}</td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </Wrapper>
  );
};

export default Contigence;
const Wrapper = styled.div`
  .container {
    padding: 10px;
    .exportexcel {
      margin: 10px;
    }
    table {
      font-family: arial, sans-serif;
      border-collapse: collapse;
      width: 100%;
    }

    td,
    th {
      border: 1px solid #dddddd;
      text-align: left;
      padding: 8px;
    }

    tr:nth-child(even) {
      // background-color: #dddddd;
    }
  }
`;
