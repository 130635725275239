import {
  Backdrop,
  Button,
  CircularProgress,
  InputAdornment,
  TextField,
} from "@mui/material";
import axios from "axios";
import React, { Component, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { FileUploader } from "react-drag-drop-files";
import PersonSearchRoundedIcon from "@mui/icons-material/PersonSearchRounded";
import WarningSnackBar from "components/Dashboard/Snackbar/WarningSnackBar";
import SuccessSnackBar from "components/Dashboard/Snackbar/SuccessSnackBar";
import { useDownloadExcel } from "react-export-table-to-excel";
import FileDownloadIcon from "@mui/icons-material/FileDownload";

const fileTypes = ["xls", "xlsx"];

const ResultatImportExcel = (props) => {
  const [data, setData] = useState([]);
  const [key, setkey] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [file, setFile] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [filtered, setFiltered] = useState([]);
  const [openError, setOpenError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [succesMessage, setSuccesMessage] = useState("");
  const [openSnackSuccess, setOpenSnackSuccess] = useState(false);

  const [typeResultat, setTypeResultat] = React.useState([
    {
      abrev: "AP",
      libelle: "Admis en PRO",
    },
    {
      abrev: "LP",
      libelle: "Liste d'attente en PRO",
    },
    {
      abrev: "AT",
      libelle: "Amis en IG Toliara",
    },
    {
      abrev: "LT",
      libelle: "Amis en IG Toliara",
    },
    {
      abrev: "AF",
      libelle: "Amis en IG Fianarantsoa",
    },
    {
      abrev: "LF",
      libelle: "Amis en IG Fianarantsoa",
    },
    {
      abrev: "NA",
      libelle: "Non Admis",
    },
  ]);
  const tableRef = useRef(null);
  const [exportExcel, setExportExcel] = useState(false);

  const handleChange = (file) => {
    setFile(file);
  };

  const saveExcel = (e) => {
    e.preventDefault();
    if (file !== null) {
      setOpen(true);
      let formData = new FormData();
      formData.set("file", file);
      axios.post("/resultat/uploadresultat", formData).then((res) => {
        setOpen(false);
        fetchData(res.data.filename);
        setSuccesMessage("Le fichier a bien été importer");
        setOpenSnackSuccess(true);
      });
    } else {
      setErrorMessage("Veillez choisir au plus un fichier excel");
      setOpenError(true);
    }
  };

  const fetchData = (filename) => {
    setOpen(true);
    axios.get("/resultat/generate/resultat/contact/" + filename).then(
      (res) => {
        if (res.data.resultat === true) {
          setkey(Object.keys(res.data.data[0]));
          setData(res.data.data);
          setFiltered(res.data.data);
          setOpen(false);
        } else {
          setOpen(false);
        }
      },
      (error) => {
        setOpen(false);
      }
    );
  };

  const decoderTypeResultat = (abbrev) => {
    return typeResultat.filter((d) => d.abrev === abbrev)[0].libelle;
  };

  useEffect(() => {
    fetchData("resultat2023");
  }, []);

  const handleSearch = () => {
    if (searchQuery.length > 0 || searchQuery === "") {
      let filteredTmp = data.filter((data) => {
        if (
          data["NUMERO"].toString().includes(searchQuery.toLowerCase()) ||
          data["NOM ET PRENOM"]
            .toLowerCase()
            .includes(searchQuery.toLowerCase()) ||
          data["TELEPHONE"].toLowerCase().includes(searchQuery.toLowerCase())
        ) {
          return data;
        }
      });
      setFiltered(filteredTmp);
    }
  };

  /**
   * Download table contigente
   */
  const { onDownload } = useDownloadExcel({
    currentTableRef: tableRef.current,
    filename: "resultat_2023_contact",
    sheet: "REsultat 2023",
  });

  return (
    <Wrapper>
      <div className="container">
        <div className="import-file">
          <h3>Importer resultat</h3>
          <div className="input">
            <FileUploader
              handleChange={handleChange}
              name="file"
              types={fileTypes}
              label="Importer le resultat"
            />
            <Button
              onClick={saveExcel}
              style={{ marginLeft: "10px" }}
              variant="contained"
              color="success"
            >
              Importer
            </Button>
          </div>
        </div>
        <Button
          variant="contained"
          onClick={(e) => {
            e.preventDefault();
            setExportExcel(true);
            setTimeout(() => {
              onDownload();
              setExportExcel(false);
            }, 4000);
          }}
          color="success"
          style={{ margin: "10px" }}
          endIcon={<FileDownloadIcon />}
        >
          Export excel
        </Button>
        <div className="recherche">
          <TextField
            style={{
              margin: "10px",
            }}
            margin="dense"
            id="search"
            label="Recherche par Numéro - Nom - Prenoms - Numéro tél"
            type="text"
            fullWidth
            variant="outlined"
            value={searchQuery}
            onChange={(e) => {
              setSearchQuery(e.target.value);
            }}
            onKeyUp={handleSearch}
            sx={{
              maxWidth: "500px",
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="start">
                  <PersonSearchRoundedIcon />
                </InputAdornment>
              ),
            }}
          />
        </div>
        <table ref={tableRef}>
          <thead>
            <tr>
              {key.map((d, i) => (
                <td key={d + "_" + i}>{d}</td>
              ))}
            </tr>
          </thead>
          <tbody>
            {filtered.length > 0 ? (
              filtered.map((c, i) => (
                <tr key={`${i}`}>
                  {key.map((k, index) => (
                    <td key={`${i}_${index}`}>
                      {k === "RESULTAT"
                        ? `${decoderTypeResultat(c[k])} (${c[k]})`
                        : c[k]}
                    </td>
                  ))}
                </tr>
              ))
            ) : (
              <tr>
                <td style={{ textAlign: "center" }} colSpan={3}>
                  Le résultat n'est pas encore publié{" "}
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <WarningSnackBar
        open={openError}
        message={errorMessage}
        close={() => setOpenError(false)}
      />
      <SuccessSnackBar
        open={openSnackSuccess}
        message={succesMessage}
        close={() => setOpenSnackSuccess(false)}
      />
    </Wrapper>
  );
};

export default ResultatImportExcel;

const Wrapper = styled.div`
  .container {
    padding: 10px;
    .import-file {
      width: 50%;
      display: flex;
      justify-content: space-between;
      .input {
        padding: 10px;
        display: flex;
        justify-content: space-between;
      }
    }
    .exportexcel {
      margin: 10px;
    }
    table {
      font-family: arial, sans-serif;
      border-collapse: collapse;
      width: 100%;
    }

    td,
    th {
      border: 1px solid #dddddd;
      text-align: left;
      padding: 8px;
    }

    tr:nth-child(even) {
      // background-color: #dddddd;
    }
  }
`;
