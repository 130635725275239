// SelectComponent.jsx
import React from 'react';
import { StyledOption, StyledSelect } from 'styles/candidat/Select.styled';

const SelectComponent = ({ options, ...props }) => {
  return (
    <StyledSelect {...props}>
      {options.map((option, index) => (
        <StyledOption key={index} value={option.value}>
          {option.label}
        </StyledOption>
      ))}
    </StyledSelect>
  );
};

export default SelectComponent;
