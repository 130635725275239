import React from 'react';
import Nav from '../components/candidat/common/Nav';
import Footer from '../components/candidat/common/Footer';
import { Outlet } from 'react-router-dom';

export default function CandidatLayout() {
    return (
        <div className="main">
            <Nav/>
                <Outlet />
            <Footer/>
        </div>
    )
}