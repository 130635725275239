import React, { useState } from 'react';
import styled from 'styled-components';
import logo from 'assets/img/eni-logo.png';
import * as styles from 'styles/variables';
import FormButton from 'components/FormUI/Button';
import { FormControl, OutlinedInput, FormHelperText, FormLabel } from '@mui/material';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { reactLocalStorage } from 'reactjs-localstorage';
import { useNavigate } from 'react-router-dom';

export default function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [loggingIn, setLoggingIn] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoggingIn(true);
    axios
      .post('/user/login', {
        email: email,
        password: password,
      })
      .then((res) => {
        if (res.data.error) {
          setError(true);
          setErrorMessage(res.data.message);
        } else {
          //authenticated successfully
          let token = res.data.access_token;
          //store the token to the local storage
          reactLocalStorage.setObject('utilisateur', {
            token: token,
            role: res.data.role,
            firstname: res.data.firstname,
            lastname: res.data.lastname,
          });
          //setting the bearer token for axios
          let utilisateur = reactLocalStorage.get('utilisateur');
          axios.defaults.headers.common['Authorization'] = `bearer ${JSON.parse(utilisateur).token}`;

          if (res.data.role === 'president') {
            navigate('/note/ajoutNote');
          } else {
            navigate('/toor');
          }
        }
        setLoggingIn(false);
      })
      .catch((err) => {
        setLoggingIn(false);
      });
  };

  return (
    <Wrapper>
      <div className="main">
        <img src={logo} alt="logo" className="logo" />
        <h2>Connexion</h2>
        <form onSubmit={handleSubmit}>
          <div className="inputs">
            <FormControl variant="standard">
              <FormLabel htmlFor="email">Email *</FormLabel>
              <OutlinedInput
                size="small"
                id="email"
                name="email"
                className="input"
                type="email"
                required
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                disabled={loggingIn}
              />
              {/* <FormHelperText>Diso</FormHelperText> */}
            </FormControl>
            <FormControl variant="standard">
              <FormLabel htmlFor="password">Mot de passe *</FormLabel>
              <OutlinedInput
                size="small"
                id="password"
                name="password"
                className="input"
                type="password"
                required
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                disabled={loggingIn}
              />
              {/* <FormHelperText>Diso</FormHelperText> */}
            </FormControl>
            {error ? <p className="error">{errorMessage}</p> : ''}
          </div>
          <div className="btn-section">
            <FormButton type="submit" disabled={loggingIn}>
              {loggingIn ? 'Connexion en cours...' : 'Se connecter'}
            </FormButton>
            <Link to="/">Retourner à la page d’acceuil</Link>
          </div>
        </form>
      </div>
    </Wrapper>
  );
}
const Wrapper = styled.div`
  background: #fafafa;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  padding: 4%;
  .main {
    width: 100%;
    max-width: 490px;
    min-height: 600px;
    padding: 50px 3%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    background-color: white;
    border-radius: 25px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    img {
      width: 100px;
    }
    h2 {
      margin: 45px 0;
      color: ${styles.colors.darkBlue};
    }
    form {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .inputs {
        display: flex;
        flex-direction: column;
        gap: 20px;
        margin-bottom: 40px;
        position: relative;
        label {
          font-weight: bold;
        }
        p.error {
          color: red;
          font-weight: bold;
          font-size: 0.8em;
          position: absolute;
          width: 100%;
          bottom: -45px;
        }
        .input:disabled {
          opacity: 0.3;
        }
      }
      .btn-section {
        display: flex;
        flex-direction: column;
        gap: 20px;
        margin-top: 50px;
        a {
          text-align: center;
        }
      }
    }
  }
`;
