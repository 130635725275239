import React, { useEffect, useState } from "react";
import styled from "styled-components";

import Logo from "../../../assets/img/eni-logo.png";

import PhoneIcon from "@mui/icons-material/Phone";
import EmailIcon from "@mui/icons-material/Email";
import FacebookIcon from "@mui/icons-material/Facebook";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import LocationOnIcon from "@mui/icons-material/LocationOn";

import * as styles from "../../../styles/variables";

export default function Footer() {
  const [date, setDate] = useState("2023");

  useEffect(() => {
    setDate(new Date().getFullYear());
  }, []);

  return (
    <FooterWrapper>
      <div className="top-container">
        <div className="content">
          <img src={Logo} className="logo" />
          <div className="item-container">
            <div className="item">
              <h3>A propos</h3>
              <p style={{ maxWidth: 300 }}>
                Site web pour les inscriptions en ligne au concours d’entrée à
                l’ENI
              </p>
            </div>
            <div className="item">
              <h3>Contact</h3>
              <div className="sub-item">
                <PhoneIcon className="icon" />
                <p>+261 34 05 733 36 / +261 32 15 204 28 </p>
              </div>
              <div className="sub-item">
                <EmailIcon className="icon" />
                <p>scolarite@eni.mg </p>
              </div>
              <a
                className="sub-item"
                href="https://www.facebook.com/EcoleNationaleInformatiqueFianarantsoa"
              >
                <FacebookIcon className="icon" />
                <p>ENI Ofisialy</p>
              </a>
              <div
                className="sub-item"
                href="https://www.linkedin.com/school/eni-fianarantsoa/about/"
              >
                <LinkedInIcon className="icon" />
                <p>Ecole Nationale d'Informatique (ENI) Fianarantsoa</p>
              </div>
            </div>
            <div className="item">
              <h3>Localisation</h3>
              <div className="sub-item">
                <LocationOnIcon className="icon" />
                <p>Tanambao Fianarantsoa</p>
              </div>
              <div style={{ width: "100%" }}>
                <iframe
                  width="100%"
                  height="250"
                  frameBorder="0"
                  scrolling="no"
                  marginHeight="0"
                  marginWidth="0"
                  src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=Ecole%20Nationale%20d'Informatiquee+(Ecole%20Nationale%20d'Informatique)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
                >
                  <a href="https://www.maps.ie/distance-area-calculator.html">
                    distance maps
                  </a>
                </iframe>
              </div>
            </div>
          </div>
        </div>
      </div>
      <hr />
      <div className="bottom-container">
        <p>&copy;Ecole Nationale d'Informatique - {date}</p>
      </div>
    </FooterWrapper>
  );
}

const FooterWrapper = styled.div`
  background-color: #ebebeb;
  padding: 0 5%;

  .top-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px 0;

    .logo {
      width: 60px;
      height: auto;
      flex: 1;
    }

    .content {
      max-width: 1280px;
      width: 100%;

      .item-container {
        display: flex;
        @media (max-width: ${styles.breakpoints.md}) {
          flex-direction: column;
        }
        .item {
          flex: 1;
          margin-right: 20px;

          .sub-item {
            display: flex;
            align-items: center;
            margin: 10px 0;

            text-decoration: none;
            color: #000;
            p {
              font-size: 14px;
              margin: 0;
            }
            .icon {
              margin-right: 10px;
            }
          }
        }
      }
    }
  }

  .bottom-container {
    margin-top: 20px;
    display: flex;
    justify-content: center;

    p {
      font-weight: bold;
      font-size: 14px;
    }
  }
`;
